export const accordion = {
  mounted: (node: HTMLElement, params): any => {
    const triggers = node.querySelectorAll<HTMLElement>('.js-accordion-trigger')
    const items = [...node.querySelectorAll<HTMLElement>('.js-accordion-item')]

    const scrolly = (id: string) => {
      setTimeout(() => {
        const item = items.find(el => el.dataset.id === id)

        if (!item) return

        const offset = item.getBoundingClientRect().top + window.pageYOffset

        window.scrollTo({ top: offset, behavior: 'smooth' })

        item.classList.add('is-open')
      }, 1200)
    };

    const checkUrl = () => {
      const { hash } = window.location

      if (hash) {
        const preparedHash = hash.replace('#', '')

        scrolly(preparedHash)
      }
    }

    const addHashHandler = el => {
      const hash = el.dataset.id

      const newPath = `${window.location.pathname}#${hash}`

      window.history.replaceState(null, null, newPath)
    }

    if (params.value?.saveHash) {
      checkUrl()
    }

    triggers.forEach((el: HTMLElement, index) => {
      el.addEventListener('click', () => {
        triggers.forEach((elem: HTMLElement, ind) => {
          if (index !== ind) {
            elem.closest('.js-accordion-item')?.classList.remove('is-open')
          }
        })

        const elWrapper = el.closest('.js-accordion-item')

        elWrapper?.classList.toggle('is-open')

        if (params.value?.saveHash) {
          addHashHandler(elWrapper)
        }
      })
    })
  }
}
