import urlTemplate from 'url-template'
import { isEmpty } from 'lodash'

const filterUrl = urlTemplate.parse('/{/filters*}/')

export function friendlyUrlParser() {
  let path = window.location.pathname
  const zero = 0
  const parsedUrl: Record<string, string[]> = {}
  if (path.indexOf('filters') > zero) {
    path = path.split('/page')[0]
    const hashes = path.split('filters/')[1].split('/')
    hashes.map((hash) => {
      const [key] = hash.split(':')
      let val: string | string[]
      [, val] = hash.split(':')

      parsedUrl[key] = []

      if (val) {
        if (val.indexOf(',') > zero) {
          val = val.split(',')
          val.forEach((v) => {
            if (v.indexOf('/') > zero) {
              parsedUrl[key].push(decodeURIComponent(v.split('/')[zero]))
            } else {
              parsedUrl[key].push(decodeURIComponent(v))
            }
          })
        } else if (val.indexOf('/') > zero) {
          parsedUrl[key].push(decodeURIComponent(val.split('/')[zero]))
        } else {
          parsedUrl[key].push(decodeURIComponent(val))
        }
      }
    })
  }
  return parsedUrl
}
// export function friendlyUrlParser(splittedPart: string) {
//   let path = decodeURIComponent(new URL(window.location.href).toString());
//   const zero = 0;
//   let page = 1
//   // let p = ''
//   const parsedUrl: Record<string, string[]> = {};
//   const [pathWithoutPage, p = ''] = path.split('/page');
//   if (p) {
//     page = Number(p.split('/')?.[1]) || page;
//   }
//   if (pathWithoutPage && pathWithoutPage.includes(splittedPart)) {
//     [, path] = pathWithoutPage.split(splittedPart);
//     if (!path) {
//       return { parsedUrl, page };
//     }
//     const hashes = path.split('/')[0].split(';').filter(hash => !!hash);
//     hashes.map(hash => {
//       let val: string | string[]
//       const [key] = hash.split('=');
//       [, val] = hash.split('=');

//       parsedUrl[key as string] = [];
//       if (val && val.includes(',')) {
//         val = val.split(',');
//         val.forEach(v => {
//           if (v.includes('/')) {
//             parsedUrl[key].push(decodeURIComponent(v.split('/')[zero]));
//           } else {
//             parsedUrl[key].push(decodeURIComponent(v));
//           }
//         });
//       } else if (val && val.includes('/')) {
//         parsedUrl[key].push(decodeURIComponent(val.split('/')[zero]));
//       } else {
//         parsedUrl[key].push(decodeURIComponent(val));
//       }
//       return { parsedUrl, page };
//     });
//   }
//   return { parsedUrl, page };
// }

export function updateFilterUrl(params, base) {
  let fullUrl = base

  const zero = 0
  const length = 2

  if (params.filters) {
    const keys = Object.keys(params.filters)

    for (let x = 0; x <= keys.length; x++) {
      if (params.filters[keys[x]] !== undefined) {
        if (zero === params.filters[keys[x]].length) {
          delete params.filters[keys[x]]
        }
      }
    }
  }

  let url = filterUrl.expand(params)

  url = url.replace(/;/, '')
  url = url.replace(/\//, '')
  url = url.replace(/%2C/g, ',')
  url = url.replace(/=/g, ':')

  if (params.label) {
    fullUrl = `${fullUrl}${params.label}/`
  }

  if (url.length > length) {
    fullUrl += `filters${url}`
  }

  if (params.pagination.page && params.pagination.page !== 1) {
    fullUrl = `${fullUrl}page/${params.pagination.page}/`
  }

  window.history.pushState({ url: fullUrl }, '', fullUrl)
}

export const removeEmptyFilters = (filters, config = { reverseBoolean: false }) => {
  const prepared = JSON.parse(JSON.stringify(filters))

  Object.keys(prepared).forEach(k => {
    if (typeof(prepared[k]) === 'boolean') {
      if (config.reverseBoolean) {
        if (prepared[k]) {
          delete prepared[k]
        } else {
          prepared[k] = prepared[k].toString()
        }
      } else if (!prepared[k]) {
        delete prepared[k]
      }
    } else if (typeof(prepared[k]) === 'number') {
      if (!prepared[k]) {
        delete prepared[k]
      }
    } else if (isEmpty(prepared[k])) {
      delete prepared[k]
    }
  })

  return prepared
}

export const prepareParamsForUrl = (params, config?) => {
  const prepared = JSON.parse(JSON.stringify(params))
  const shift = 1
  const page = Math.floor(prepared.pagination.offset / prepared.pagination.limit) + shift

  prepared.pagination.page = page

  if (prepared.filters) {
    prepared.filters = removeEmptyFilters(prepared.filters, config)
  }

  return prepared
}

export const prepareAttributes = (items, itemType = 'select') => {
  return items.map(item => {
    if (item.isDynamic) {
      item.values = item.choices.map(c => {
        const obj = {
          title: c.valueVerbose,
          value: c.slug,
        }

        return obj
      })

      delete item.choices

      const singleWidgets = ['switch', 'select']

      if (singleWidgets.includes(item.type)) {
        item.config = { multiple: false }
      } else {
        item.config = { multiple: true }
      }

      item.type = itemType
    }

    return item
  })
}