import { createReceiver } from '@/plugins/resource/resource'
import { friendlyUrlGenerator } from '@/packages/vue-friendly'
import { baseResource } from '@aspectus/resource'
import { urlConstructor } from '@/plugins/resource/api'

// Models
const MODEL = 'diseases'

export const DISEASES_LIST_URL = urlConstructor(`${MODEL}/list/`, ['ids', 'search', 'is_favorite'])

export const DISEASES_CATALOG_URL = `/catalog/${MODEL}/`
export const DISEASES_CATALOG_CHAPTER_URL = `/${MODEL}/`

export const DISEASES_FILTERS_URL = urlConstructor(`diseases/filter/`, [])

export const DISEASES_TREATMENT_CATALOG_URL = `/diseases/{slug}/{treatmentSlug}/`

export const diseasesListResource = createReceiver(DISEASES_LIST_URL)

// filter resources
export const diseasesCatalogResource = friendlyUrlGenerator([
  DISEASES_CATALOG_URL, 'ajax'
], baseResource)
export const diseasesCatalogChapterResource = friendlyUrlGenerator([
  DISEASES_CATALOG_CHAPTER_URL, 'ajax'
], baseResource)
export const diseasesTreatmentCatalogResource = friendlyUrlGenerator([
  DISEASES_TREATMENT_CATALOG_URL, 'ajax'
], baseResource)

export const diseasesFiltersResource = createReceiver(DISEASES_FILTERS_URL)
