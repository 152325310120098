import { App } from 'vue'

import ControlComponents from '@/components/controls'
import FormComponents from '@/components/forms'
import IconsComponents from '@/components/i'
import LocalizationComponents from '@/components/localization'
import SearchComponents from '@/components/search'
import UiComponents from '@/components/ui'
import FavoriteComponents from '@/components/favorite'
import FacilityComponents from '@/components/facility'
import DoctorComponents from '@/components/doctor'
import ReviewComponents from '@/components/review'
import HelpCenterComponents from '@/components/help-center'
import VideoComponents from '@/components/video'
import IllnessComponents from '@/components/illness'
import SlidersComponents from '@/components/sliders'
import NewsComponents from '@/components/news'
import ArticlesComponents from '@/components/articles'
import SearchFilterComponents from '@/components/search-filter'
import ServiceComponents from '@/components/service'
import AppointmentComponents from '@/components/appointment'
import TypeComponents from '@/components/typo'
import CookiesComponents from '@/components/cookies'
import SpecializationComponents from '@/components/specialization'

const install = (app: App<Element>): void => {
  app
    .use(ControlComponents)
    .use(FormComponents)
    .use(IconsComponents)
    .use(LocalizationComponents)
    .use(SearchComponents)
    .use(UiComponents)
    .use(FavoriteComponents)
    .use(FacilityComponents)
    .use(DoctorComponents)
    .use(ReviewComponents)
    .use(HelpCenterComponents)
    .use(VideoComponents)
    .use(IllnessComponents)
    .use(SlidersComponents)
    .use(NewsComponents)
    .use(ArticlesComponents)
    .use(SearchFilterComponents)
    .use(ServiceComponents)
    .use(AppointmentComponents)
    .use(TypeComponents)
    .use(CookiesComponents)
    .use(SpecializationComponents)
}

export default {
  install,
}
