import { App } from 'vue'
import { tableWrapper } from './table'
import { navigationScroll } from './navigationScroll'
import { tabsSimple } from './tabs'
import { accordion } from './accordion'

const register = (app: App<Element>): void => {
  app.directive('table-wrapper', tableWrapper)
  app.directive('navigation-scroll', navigationScroll)
  app.directive('tabs-simple', tabsSimple)
  app.directive('accordion', accordion)
}

export default {
  register,
}
