import { i18n } from '@/i18n'
import { defineAsyncComponent } from 'vue'
import { ARTICLES_LIST_URL } from '@/services/articles.service'
import { DOCTOR_LIST_URL } from '@/services/doctor.service'
import { NEWS_LIST_URL } from '@/services/news.service'
import { FAVORITE_MEDICAL_URL } from '@/services/likes.service'
import { DISEASES_LIST_URL } from '@/services/diseases.service'

const { t } = i18n.global

export enum TABS {
  DOCTOR = 'doctor',
  MEDICAL = 'medical',
  NEWS = 'news',
  ARTICLES = 'articles',
  DISEASES = 'diseases',
}

export const FAVORITES_TABS = [
  {
    value: TABS.DOCTOR,
    label: t('Doctors'),
    endpoint: DOCTOR_LIST_URL,
    keyCounter: 'apps_doctors.doctor',
    limit: 4,
    listComponent: defineAsyncComponent(() => import('./tabs/Doctors.vue')),
    emptyResult: t('You have not added any doctor yet'),
  },
  {
    value: TABS.MEDICAL,
    label: t('Medical facilities'),
    endpoint: FAVORITE_MEDICAL_URL,
    keyCounter: 'apps_medical_facilities.branchmedicalfacility',
    limit: 4,
    wrapClass: 'g-cols--6-xl',
    listComponent: defineAsyncComponent(
      () => import('./tabs/FacilitiesBranches.vue')
    ),
    emptyResult: t('You have not added any medical facility yet'),
  },
  {
    value: TABS.NEWS,
    label: t('News'),
    endpoint: NEWS_LIST_URL,
    keyCounter: 'news.post',
    limit: 6,
    listComponent: defineAsyncComponent(() => import('./tabs/News.vue')),
    emptyResult: t('You have not added any post yet'),
  },
  {
    value: TABS.ARTICLES,
    label: t('Articles'),
    endpoint: ARTICLES_LIST_URL,
    keyCounter: 'articles.post',
    limit: 6,
    wrapClass: 'g-cols--6-md g-cols--3-xl',
    listComponent: defineAsyncComponent(() => import('./tabs/Articles.vue')),
    emptyResult: t('You have not added any post yet'),
  },
  {
    value: TABS.DISEASES,
    label: t('Diseases'),
    endpoint: DISEASES_LIST_URL,
    keyCounter: 'who_icd.entity.doctor',
  limit: 6,
  wrapClass: 'g-cols',
  listComponent: defineAsyncComponent(() => import('./tabs/Diseases.vue')),
  emptyResult: t('You have not added any illness yet')
  },
]
