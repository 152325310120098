import { r, createSender, createFileSender } from '@/plugins/resource/resource'
import { queryPostfix, prefixAPI, urlConstructor } from '@/plugins/resource/api'

const MODEL = 'sites'
const MODEL_LANGUAGES = 'communication-languages'
const MODEL_ORGANIZATION = 'organization'
const MODEL_SERVICES = 'services'
const MODEL_SPECIALIZATIONS = 'specializations'
const MODEL_ATTRIBUTES = 'attributes'

const MODEL_YOUSCORE = 'youscore'
const MODEL_TYPO = 'site-typo'
const MODEL_TELEGRAM = 'telegram'


export const COMMUNICATION_LANGUAGES_LIST_URL = urlConstructor(MODEL_LANGUAGES+'/list/', [])

export const SPECIALIZATION_LIST_URL = urlConstructor('specializations/list/', ['search'])

export const ORGANIZATION_SOCIAL_LIST_URL: string = prefixAPI('/social/list/', MODEL_ORGANIZATION)

export const CREATE_UPLOAD_URL = prefixAPI('upload-create/', )

export const GENERAL_SERVICES_LIST_URL = urlConstructor(MODEL_SERVICES + '/list/', ['specialization'])
export const GENERAL_SPECIALIZATIONS_LIST_URL = urlConstructor(MODEL_SPECIALIZATIONS + '/list/', ['search', 'ids'])

export const ATTRIBUTES_VALUES_LIST_URL = urlConstructor(MODEL_ATTRIBUTES + '/values/', ['search', 'attribute_ids'])

export const TYPO_CREATE_UTL = '/create/'


export const createUploadResource = createFileSender(CREATE_UPLOAD_URL)
export const sitesConfigsList = r('/configs/list/', MODEL)
export const organizationSocialList = r('/social/list/',  MODEL_ORGANIZATION,)
export const checkCompanyResource = r('/check-company/',  MODEL_YOUSCORE, createSender)
export const typoCreateResource = r(TYPO_CREATE_UTL, MODEL_TYPO, createSender)
export const telegramGetDeepLinkResource = r('/get-deep-link/', MODEL_TELEGRAM)
