import type { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'
import { receiveRoute } from '@m/cabinet/components/views/utils'
import Receive from '@m/cabinet/components/views/Receive.vue'
import { facilityServicesRetrieveResource } from '@/services/facility.service'
import { toHash } from '@m/cabinet/utils'
import { doctorAccessPermission, accessPermission } from '@/modules/user/permissions'
import { IS_DOCTOR } from '@/modules/user/accessTokens'

import {
  GENERAL_TAB_KEY,
  SPECIALIZATION_TAB_KEY,
  DOCUMENTS_TAB_KEY,
  PLACE_OF_EMPLOYMENT_TAB_KEY,
} from '@/modules/cabinet/modules/Doctor/const'

import { MEDIA_TAB_KEY, SCHEDULE_TAB_KEY } from '@m/cabinet/modules/Branch/const'

export const DOCTOR_BASE = 'cabinet:doctor'
const p = (name: string) => DOCTOR_BASE + ':' + name
const s = (name: string) => name+'/'

export const DOCTOR_VIEW_NAME = p('view')
export const DOCTOR_NOT_ADDED_VIEW_NAME = p('not-added')

export const DOCTOR_GENERAL_VIEW_NAME = p(GENERAL_TAB_KEY)
export const DOCTOR_SPECIALIZATION_VIEW_NAME = p(SPECIALIZATION_TAB_KEY)
export const DOCTOR_DOCUMENTS_VIEW_NAME = p(DOCUMENTS_TAB_KEY)
export const DOCTOR_PLACE_OF_EMPLOYMENT_VIEW_NAME = p(PLACE_OF_EMPLOYMENT_TAB_KEY)

const View = () => import('./views/View.vue')
const NotAdded = () => import('./views/NotAdded.vue')

const General = () => import('@m/cabinet/modules/Doctor/tabs/General.vue')
const Documents = () => import('@m/cabinet/modules/Doctor/tabs/Documents.vue')
const Specialization = () => import('@m/cabinet/modules/Doctor/tabs/Specialization.vue')
const PlaceOfEmployment = () => import('@m/cabinet/modules/Doctor/tabs/PlaceOfEmployment.vue')

const BASE_MODEL_NAME = 'doctor/'

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route(':id/', View, DOCTOR_VIEW_NAME, {
      meta: {
        title: () => $t('Doctor profile'),
        ...accessPermission([IS_DOCTOR], options),
      },
      children: [
        route(s(GENERAL_TAB_KEY), General, DOCTOR_GENERAL_VIEW_NAME, {
          meta: {
            title: () => $t('Doctor general information'),
          },
        }),
        route(s(SPECIALIZATION_TAB_KEY), Specialization, DOCTOR_SPECIALIZATION_VIEW_NAME, {
          meta: {
            title: () => $t('Doctor specialization'),
          },
        }),
        route(s(DOCUMENTS_TAB_KEY), Documents, DOCTOR_DOCUMENTS_VIEW_NAME, {
          meta: {
            title: () => $t('Doctor documents'),
          },
        }),
        route(s(PLACE_OF_EMPLOYMENT_TAB_KEY), PlaceOfEmployment, DOCTOR_PLACE_OF_EMPLOYMENT_VIEW_NAME, {
          meta: {
            title: () => $t('Doctor place of employment'),
          },
        }),
        
      ]
    }),
    route('add/', NotAdded, DOCTOR_NOT_ADDED_VIEW_NAME, {
      meta: {
        title: () => $t('Add Doctor'),
        ...accessPermission([IS_DOCTOR], options),
      },
    }),
  ]
}

export { BASE_MODEL_NAME }