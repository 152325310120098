import { type App, type DefineComponent, type Plugin } from 'vue'
import { RouteRecordRaw } from 'vue-router'
import { route, prefixRoutes } from '@router/utils'
import type { Options } from '@/router'

import { 
  createRoutes as createProfileRoutes, BASE_MODEL_NAME as PROFILE_ROOT_PATH,
  createSettingsRoutes, BASE_SETTINGS_MODEL_NAME, SETTINGS_BASE,
  createNotificationsRoutes, BASE_NOTIFICATIONS_MODEL_NAME, NOTIFICATIONS_BASE,
 } from './modules/Profile/router'
import { createRoutes as createFacilityRoutes, BASE_MODEL_NAME as FACILITY_ROOT_PATH } from './modules/Facility/router'

import { createRoutes as createDoctorRoutes, BASE_MODEL_NAME as DOCTOR_ROOT_PATH, DOCTOR_BASE } from '@m/cabinet/modules/Doctor/router'
import { createRoutes as createBranchRoutes, BASE_MODEL_NAME as BRANCH_ROOT_PATH } from '@m/cabinet/modules/Branch/router'
import { createRoutes as createAppointmentRoutes, BASE_MODEL_NAME as APPOINTMENT_ROOT_PATH } from '@m/cabinet/modules/Appointment/router'
import { createRoutes as createAppointmentReviewsRoutes, BASE_MODEL_NAME as APPOINTMENT_REVIEWS_ROOT_PATH } from '@m/cabinet/modules/AppointmentReviews/router'
import { createRoutes as createReviewsRoutes, BASE_MODEL_NAME as REVIEWS_ROOT_PATH } from '@m/cabinet/modules/Reviews/router'
import { createRoutes as createFavoritesRoutes, BASE_MODEL_NAME as FAVORITES_ROOT_PATH } from '@m/cabinet/modules/Favorites/router'

import { doctorAccessPermission, accessPermission } from '@/modules/user/permissions'
import { IS_DOCTOR, IS_PATIENT } from '@/modules/user/accessTokens'

// import { createRoutes as createTransactionsRoutes } from './modules/Transactions/router'
import { i18n } from '@/i18n'

import { PROFILE_VIEW_NAME } from './routes'

const CabinetView = () => import('./views/CabinetView.vue')
const CabinetInstaller = () => import('./components')
const Route403 = () => import('./views/Route403.vue')
const Route404 = () => import('./views/Route404.vue')

function installer<T extends DefineComponent>(
  View: any,
  Installer: () => Promise<T>,
  app: App<Element>
): () => Promise<Awaited<T>> {
  return function (): Promise<Awaited<T>> {
    return Promise.all([View(), Installer()]).then(function (result) {
      const [ViewComponent, installer] = result
      app.use(installer?.default || installer?.install || installer)
      return ViewComponent
    })
  }
}

const createCabinetRoutes = (options: Options): RouteRecordRaw[] => {
  return [
    prefixRoutes(PROFILE_ROOT_PATH, createProfileRoutes(options)),
    prefixRoutes(FACILITY_ROOT_PATH, createFacilityRoutes(options)),
    prefixRoutes(BRANCH_ROOT_PATH, createBranchRoutes(options)),
    prefixRoutes(DOCTOR_ROOT_PATH, createDoctorRoutes(options), { name: DOCTOR_BASE,
      meta: {
        ...doctorAccessPermission([IS_DOCTOR], options),
      }
     }),
    prefixRoutes(BASE_SETTINGS_MODEL_NAME, createSettingsRoutes(options), { name: SETTINGS_BASE,
      meta: {
        ...accessPermission([IS_DOCTOR], options),
      }
     }),
    prefixRoutes(BASE_NOTIFICATIONS_MODEL_NAME, createNotificationsRoutes(options), { name: NOTIFICATIONS_BASE }),
    prefixRoutes(APPOINTMENT_ROOT_PATH, createAppointmentRoutes(options), { name: APPOINTMENT_ROOT_PATH }),
    prefixRoutes(REVIEWS_ROOT_PATH, createReviewsRoutes(options), { name: REVIEWS_ROOT_PATH,
      meta: {
        ...accessPermission([IS_DOCTOR], options),
      },
     }),
    prefixRoutes(APPOINTMENT_REVIEWS_ROOT_PATH, createAppointmentReviewsRoutes(options), { name: APPOINTMENT_REVIEWS_ROOT_PATH,
      meta: {
        ...accessPermission([IS_PATIENT], options),
      },
     }),
    prefixRoutes(FAVORITES_ROOT_PATH, createFavoritesRoutes(options), { name: FAVORITES_ROOT_PATH }),
  ]
} 

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { app } = options
  const { t: $t } = i18n.global
  return [
    route(
      '',
      installer<any>(
        CabinetView,
        CabinetInstaller as unknown as () => Promise<Plugin>,
        app
      ),
      'cabinet',
      {
        children: [
          ...createCabinetRoutes(options),
          {
            path: '/:pathMatch(.*)*',
            name: 'cabinet:not-found',
            component: Route404,
            meta: {
              title: () => $t('404'),
            },
          },
          {
            path: '/:pathMatch(.*)*',
            name: 'cabinet:no-access',
            component: Route403,
            meta: {
              title: () => $t('403'),
            },
          },
        ],
        redirect: { name: PROFILE_VIEW_NAME},
      }
    ),
  ]
}
