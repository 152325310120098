import type { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'
import { receiveRoute } from '@m/cabinet/components/views/utils'
import Receive from '@m/cabinet/components/views/Receive.vue'
import { facilityServicesRetrieveResource } from '@/services/facility.service'
import { toHash } from '@m/cabinet/utils'
import { 
  accessPermission, accessTypePermission, facilityAccessPermission,
  facilityDocumentFlowAccessPermission,
 } from '@/modules/user/permissions'
import { IS_DOCTOR } from '@/modules/user/accessTokens'

import {
  GENERAL_TAB_KEY,
  SERVICES_TAB_KEY,
  DOCUMENTS_TAB_KEY,
  BRANCHES_TAB_KEY,
  DOCTORS_TAB_KEY,
  SINGLE_FACILITY_TABS,
  MULTIPLE_FACILITIES_TABS,
  DOCUMENTS_FLOW
} from '@/modules/cabinet/modules/Facility/const'

import { MEDIA_TAB_KEY, SCHEDULE_TAB_KEY } from '@m/cabinet/modules/Branch/const'

const FACILITY_PREFIX = 'cabinet:facility'
const p = (name: string) => FACILITY_PREFIX + ':' + name
const s = (name: string) => name+'/'

export const FACILITY_VIEW_NAME = p('view')
export const FACILITY_NOT_ADDED_VIEW_NAME = p('not-added')

export const FACILITY_GENERAL_VIEW_NAME = p(GENERAL_TAB_KEY)
export const FACILITY_SERVICES_VIEW_NAME = p(SERVICES_TAB_KEY)
export const FACILITY_DOCUMENTS_VIEW_NAME = p(DOCUMENTS_TAB_KEY)
export const FACILITY_DOCTORS_VIEW_NAME = p(DOCTORS_TAB_KEY)
export const FACILITY_BRANCHES_VIEW_NAME = p(BRANCHES_TAB_KEY)
export const FACILITY_MEDIA_VIEW_NAME = p(MEDIA_TAB_KEY)
export const FACILITY_SCHEDULE_VIEW_NAME = p(SCHEDULE_TAB_KEY)
export const FACILITY_DOCUMENTS_FLOW_VIEWS_NAME = p(DOCUMENTS_FLOW)

export const FACILITY_SERVICE_CREATE = p('service:create')
export const FACILITY_SERVICE_EDIT = p('service:edit')

const View = () => import('./views/View.vue')
const NotAdded = () => import('./views/NotAdded.vue')
const Service = () => import('./views/Service.vue')

const Services = () => import('@m/cabinet/modules/Facility/tabs/Services.vue')
const Branches = () => import('@m/cabinet/modules/Facility/tabs/Branches.vue')
const Documents = () => import('@m/cabinet/modules/Facility/tabs/Documents.vue')
const General = () => import('@m/cabinet/modules/Facility/tabs/General.vue')
const Doctors = () => import('@m/cabinet/modules/Facility/tabs/Doctors.vue')
const Media = () => import('@m/cabinet/modules/Branch/tabs/Media.vue')
const Schedule = () => import('@m/cabinet/modules/Branch/tabs/Schedule.vue')
const DocumentFlow = () => import('@m/cabinet/modules/Facility/views/DocumentFlow.vue')

const BASE_MODEL_NAME = 'facility/'

const FACILITY_BACK_PARAMS = [{ key: 'id', from: 'facility', path: ['id'] }]
const FALLBACK_SERVICES_PARAMS = {
  fallbackRouteName: FACILITY_SERVICES_VIEW_NAME,
  parameters: FACILITY_BACK_PARAMS,
}

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route(':id/', View, FACILITY_VIEW_NAME, {
      meta: {
        title: () => $t('Medical facility profile'),
        ...facilityAccessPermission([IS_DOCTOR], options),
      },
      redirect: { name: FACILITY_GENERAL_VIEW_NAME },
      children: [
        route(s(GENERAL_TAB_KEY), General, FACILITY_GENERAL_VIEW_NAME, {
          meta: {
            title: () => $t('Medical facility general information'),
          },
        }),
        route(s(SERVICES_TAB_KEY), Services, FACILITY_SERVICES_VIEW_NAME, {
          meta: {
            title: () => $t('Medical facility services'),
          },
        }),
        route(s(DOCUMENTS_TAB_KEY), Documents, FACILITY_DOCUMENTS_VIEW_NAME, {
          meta: {
            title: () => $t('Medical facility documents'),
          },
        }),
        route(s(BRANCHES_TAB_KEY), Branches, FACILITY_BRANCHES_VIEW_NAME, {
          meta: {
            title: () => $t('Medical facility branches'),
          },
        }),
        // prefixRoutes(s(BRANCHES_TAB_KEY), [
        //   route('list/', Branches, FACILITY_BRANCHES_VIEW_NAME, {
        //     title: () => $t('Medical facility branches'),
        //   }),
        //   ...createBranchRoutes(options)
        // ], {meta: {}},),
        route(s(DOCTORS_TAB_KEY), Doctors, FACILITY_DOCTORS_VIEW_NAME, {
          meta: {
            title: () => $t('Medical facility doctors'),
          },
        }),
        route(s(MEDIA_TAB_KEY), Media, FACILITY_MEDIA_VIEW_NAME, {
          meta: {
            title: () => $t('Medical facility media'),
          },
        }),
        route(s(SCHEDULE_TAB_KEY), Schedule, FACILITY_SCHEDULE_VIEW_NAME, {
          meta: {
            title: () => $t('Medical facility schedule'),
          },
        }),
      ]
    }),
    route('add/', NotAdded, FACILITY_NOT_ADDED_VIEW_NAME, {
      meta: {
        title: () => $t('Add medical facility'),
        ...facilityAccessPermission([IS_DOCTOR], options),
      },
    }),

    route(s(DOCUMENTS_FLOW), DocumentFlow, FACILITY_DOCUMENTS_FLOW_VIEWS_NAME, {
      meta: {
        title: () => $t('Document flow'),
        ...facilityDocumentFlowAccessPermission([IS_DOCTOR], options),
      }
    }),
    prefixRoutes(':facility/', [
      prefixRoutes('services/', [
        route('create/', Service, FACILITY_SERVICE_CREATE, {
          meta: {
            title: () =>  $t('Create service'),
            ...FALLBACK_SERVICES_PARAMS,
            ...facilityAccessPermission([IS_DOCTOR], options),
          }
        }),
        receiveRoute(
          facilityServicesRetrieveResource,
          [
            route('edit/', Service, FACILITY_SERVICE_EDIT, {
              meta: {
                title: () => $t('Edit service'),
                ...FALLBACK_SERVICES_PARAMS,
                ...facilityAccessPermission([IS_DOCTOR], options),
              }
            }),
            { path: '', redirect: { name: FACILITY_SERVICE_EDIT } },
          ],
          Receive,
          ''
        ),
      ]),
      
    ]),
    
  ]
}

export { BASE_MODEL_NAME }