import { curry, isEmpty } from 'ramda'
import makeTemplateGetter from '@aspectus/resource-template-url-getter'
import { baseResource } from '@/plugins/resource'

import {
  // defaultDataTransformer,
  jsonTransformer,
} from '@aspectus/resource-commons'

const filtersGetterBase = makeTemplateGetter('{/filters*}')
const filtersGetter = (parameters: string) => {
  let url = filtersGetterBase(parameters).slice(1)

  url = url.replace(/=/g, ':')

  return url || null
}

const partGetter = (name: string, prefix: string) => {
  const base = makeTemplateGetter(`${prefix}{/${name}}`)
  return (parameters: Record<string, unknown>) => {
    const result = base(parameters)
    return result === prefix ? null : result
  }
}

const pageGetter = partGetter('page', 'page')
const labelGetter = makeTemplateGetter('{label}')
const categoryGetter = makeTemplateGetter('{category}')
const getParametersGetter = makeTemplateGetter('{?limit,offset}')
const getSearchGetter = makeTemplateGetter('{?search}')
const getSearchPaginationGetter = makeTemplateGetter('{?search,limit,offset}')

const order_map = {
  label: labelGetter,
  category: categoryGetter,
  filters: filtersGetter,
  pagination_offset: getParametersGetter,
  page: pageGetter,
  search: getSearchGetter,
  search_pagination: getSearchPaginationGetter,
} as const

const initOrder = {
  path: ['prefix', 'label', 'postfix', 'filters', 'pagination_offset'],
}

export const urlGetter = curry(
  (
    [prefix, postfix]: [string, string],
    {
      path,
      query = [],
    }: { path: (keyof typeof order_map)[]; query?: (keyof typeof order_map)[] },
    p
  ) => {
    const base = { prefix, postfix }
    const path_parts = path.reduce(
      (acc, x, i) => {
        acc[i] = order_map[x] ? order_map[x](p) : base[x as keyof typeof base]
        return acc
      },
      Array.from({ length: path.length }, () => null)
    )
    const query_params = query.reduce((acc, x) => {
      acc.push(order_map[x](p))
      return acc
    }, [] as string[])
    let url = `${path_parts.filter((x) => x !== null).join('/')}`
      .replace(/\/+/img, '/')
      .replace(/\/\//gim, '/')
      .replace(/\/\//gim, '/')
      .replace(/%2C/g, ',') + query_params.join('')

    if (p.filters) {
      const filtersIsEmpty = Object.values(p.filters).every(v => {
        if (!v) {
          return true
        }

        return isEmpty(v)
      })

      if (!filtersIsEmpty && postfix.includes('ajax')) {
        url = url.replace(postfix, `${postfix}/filters`)
      }
    }

    if (p.replace) {
      Object.keys(p.replace).forEach(k => {
        url = url.replace(`{${k}}`, p.replace[k])
      })
    }

    return url
  }
)

export const friendlyUrlGenerator = (
  url: string[],
  resource: typeof baseResource,
  order = initOrder
) => resource.url(urlGetter(url, order)).transform(jsonTransformer)
// .transform(defaultDataTransformer)
