import type { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'
import { accessPermission } from '@/modules/user/permissions'
import { IS_PATIENT } from '@/modules/user/accessTokens'

const APPOINTMENT_PREFIX = 'cabinet:appointment-reviews'
const p = (name: string) => APPOINTMENT_PREFIX + ':' + name

export const APPOINTMENT_REVIEWS_LIST_VIEW_NAME = p('list')

const View = () => import('./views/View.vue')

const BASE_MODEL_NAME = 'appointment-reviews/'


export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global

  return [
    route('list/', View, APPOINTMENT_REVIEWS_LIST_VIEW_NAME, {
      meta: {
        title: () => $t('Reviews'),
        ...accessPermission([IS_PATIENT], options),
      }
    }),
    
  ]
}

export { BASE_MODEL_NAME }